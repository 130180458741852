import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service";

export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },

  name: "news",
  data() {
    return {
      requiredRule: [v => !!v || "Field is required"],
      dateRule: [
        v => !!v || "Field is required",
        v => v.length > 10 || "Field is required"
      ],
      linkShopExclusiveRule: [true],
      isSaving: false,
      newsId: null,
      valid: false,
      news: {
        title: "",
        base64Image: "",
        body: "",
        lead: "",
        link: "",
        from: null,
        to: null,
        enabled: false,
        blogId: "",
        shopId: "",
        allCities: false,
      },
      blogs: [],
      shops: [],
      blogsIsLoading: false,
      shopsIsLoading: false,
      dateFromMenu: false,
      dateToMenu: false
    };
  },
  created() {
    this.newsId = this.$route.params.newsId;
    let title = this.newsId != null ? "News details" : "Insert new news";
    this.$store.dispatch(SET_BREADCRUMB, [{ title: title }]);
    if (this.newsId != null) {
      ApiService.get(`api/news`, `${this.newsId}`)
        .then(response => {
          this.$log.debug("News: ", response.data);
          this.news = response.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    }
    this.searchBlogs();
    this.searchShops();
  },
  methods: {
    validate: function () {
      this.$log.debug("validate news link length: ", this.news.link.length);
      this.$log.debug("validate shopId length: ", this.news.shopId);
      if (this.news.link.length > 0 && this.news.shopId > 0){
        this.linkShopExclusiveRule = ["News can have link OR shop. Not both!"];
      } else {
        this.linkShopExclusiveRule = [true];
      }
    },
    previousState() {
      this.$router.go(-1);
    },
    endsWith(suffix, str) {
      return str.indexOf(suffix, str.length - suffix.length) !== -1;
    },
    paddingSize(value) {
      if (this.endsWith("==", value)) {
        return 2;
      }
      if (this.endsWith("=", value)) {
        return 1;
      }
      return 0;
    },
    size(value) {
      return (value.length / 4) * 3 - this.paddingSize(value);
    },
    byteSize(base64String) {
      return this.formatAsBytes(this.size(base64String));
    },
    formatAsBytes(size) {
      return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " bytes";
    },
    handleImage(event) {
      if (event && event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        // this.toBase64(file);
        this.toBase64(file, base64Data => {
          this.news.base64Image = base64Data;
        });
      }
    },
    setFileData(event, entity, field, isImage) {
      if (event && event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        if (isImage && !/^image\//.test(file.type)) {
          return;
        }
        // this.toBase64(file);
        this.toBase64(file, base64Data => {
          entity[field] = base64Data;
        });
      }
    },
    createBase64Image(fileObject) {
      const fileReader = new FileReader();
      fileReader.onload = e => {
        this.news.image = e.target.result;
      };
      fileReader.readAsDataURL(fileObject);
    },
    toBase64(file, cb) {
      const fileReader = new FileReader();
      fileReader.onload = e => {
        const base64Data = e.target.result;
        cb(base64Data);
      };
      fileReader.readAsDataURL(file);
    },
    clearInputImage(field) {
      this.news[field] = null;
    },
    searchShops() {
      // Items have already been loaded
      if (this.shops.length > 0) return;

      // Items have already been requested
      if (this.shopsIsLoading) return;

      this.shopsIsLoading = true;
      ApiService.query("api/shops/dropdown-list")
          .then(res => {
            this.shops = res.data;
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => (this.shopsIsLoading = false));
    },
    searchBlogs() {
      // Items have already been loaded
      if (this.blogs.length > 0) return;

      // Items have already been requested
      if (this.blogsIsLoading) return;

      this.blogsIsLoading = true;
      let params = {};
      params["page"] = 1;
      params["size"] = 999;
      ApiService.query("api/blog", {
        params
      })
        .then(res => {
          this.blogs = res.data.content;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.blogsIsLoading = false));
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
    validateAndSave() {
      if (this.$refs.form.validate()) {
        this.saveNews(this.news);
      }
    },
    saveNews(news) {
      this.isSaving = true;
      this.loading = true;

      this.$log.debug("News id to be created: " + news.id);
      this.$log.debug("News name to be created: " + news.title);
      // if (news.from.indexOf("T") === -1) {
      //   news.from = news.from + "T00:00:00";
      // }
      // if (news.to.indexOf("T") === -1) {
      //   news.to = news.to + "T00:00:00";
      // }

      this.$log.debug("news.from: " + news.from);
      this.$log.debug("news.to: " + news.to);
      if (news.id != null) {
        ApiService.put(`api/news`, news)
          .then(response => {
            this.$log.debug("News created: " + response);
            this.$emit("newsSaved", "newsSaved");
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.isSaving = false;
            this.previousState();
          });
      } else {
        ApiService.post(`api/news`, news)
          .then(response => {
            this.$log.debug("News created: " + response);
            this.$emit("newsSaved", "newsSaved");
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.isSaving = false;
            this.previousState();
          });
      }
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    ...mapGetters(["currentUser"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("input", value);
        }
      }
    },
    dateFromFormatted() {
      return this.news.from
        ? moment(this.news.from).format("dddd, MMMM Do YYYY")
        : "";
    },
    dateToFormatted() {
      return this.news.to
        ? moment(this.news.to).format("dddd, MMMM Do YYYY")
        : "";
    },
    dateStartFormatted: {
      get() {
        if (this.news.from == null || this.news.from === "") return null;
        return moment
          .utc(this.news.from)
          .local()
          .format("yyyy-MM-DD HH:mm:ss");
      },
      set(value) {
        this.news.from = value;
      }
    },
    dateEndFormatted: {
      get() {
        if (this.news.to == null || this.news.to === "") return null;
        return moment
          .utc(this.news.to)
          .local()
          .format("yyyy-MM-DD HH:mm:ss");
      },
      set(value) {
        this.news.to = value;
      }
    }
  }
};
